import React from "react";
import { Helmet } from "react-helmet";
import { BaseComponent } from "../base/BaseComponent";
import BannerComponent from "./banner/BannerComponent";
import FrontBannerComponent from "./frontbanner/FrontBannerComponent";
import WalletComponent from "./wallet/WalletComponent";
import ServicesComponent from "./services/ServicesComponent";
import HowItWorksComponent from "./howitworks/HowItWorksComponent";
import TradeBriefHistoryComponent from "./tradebriefhistory/TradeBriefHistoryComponent";
import MapComponent from "./map/MapComponent";
import { SocketCustomEvents } from "../../../enums/socket";

export default class HomeComponent extends BaseComponent {
  render() {
    return (
      <>
        <Helmet>
          <title>
            BitVelocity - Best ROI of 2020, Buy Bitcoin, Sell Bitcoin, Ethereum,
            Now With BitVelocity
          </title>
          <meta
            name="description"
            content="Best ROI of 2020 buy bitcoin, sell bitcoin, ethereum, now with bitvelocity"
          />
        </Helmet>
        <FrontBannerComponent {...this.props} />
        <BannerComponent {...this.props} />
        <TradeBriefHistoryComponent {...this.props} />
        <WalletComponent {...this.props} />
        <ServicesComponent {...this.props} />
        <HowItWorksComponent {...this.props} />
        <MapComponent {...this.props} />
      </>
    );
  }

  afterComponentDidMount = () => {
    // window.addEventListener("load", this.addTawkChatWindow);
    this.addTawkChatWindow();
  };

  componentWillUnmount = () => {
    this.socket.unregisterEvent(
      SocketCustomEvents.SubscribeToBriefRecentHistory
    );
  };

  subscribedToBriefHistory = false;
  constructor(props) {
    super(props);
    this.subscribedToBriefHistory = this.SubscribeToBriefRecentHistory(
      this.subscribedToBriefHistory
    );
  }

  afterReceivingProps = () => {
    this.subscribedToBriefHistory = this.SubscribeToBriefRecentHistory(
      this.subscribedToBriefHistory
    );
  };
}
