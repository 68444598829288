import React from "react";
import { BaseComponent } from "../../base/BaseComponent";
import { Card } from "antd";
import { Link } from "react-router-dom";
import LogoText from "../../shared/Logo/LogoText";
import "./FrontBannerComponent.less";

export default class NewBannerComponent extends BaseComponent {
  render() {
    return (
      <>
        <div className="front-banner">
          <div className="front-banner-text">
            <div className="first-headline">
              <h1 className="heading-huge">
                {this.lang.FrontBannerHeading1}
                <p className="text-medium gx-font-weight-medium">
                  {this.lang.FrontBannerSubHeading11}&nbsp;
                  <span style={{ color: "#f0645b" }}>{this.lang.Free}</span>
                  &nbsp;{this.lang.FrontBannerSubHeading12}
                  <span className="text-medium">&#42;</span>
                </p>
              </h1>
            </div>
            <div className="second-headline">
              <p className="heading-medium gx-font-weight-medium">
                {this.lang.FrontBannerHeading2}
                <p className="text-medium ">
                  <LogoText />
                  &nbsp;{this.lang.FrontBannerHeading21}
                </p>
              </p>
            </div>
            <br />
            <div className="third-headline">
              <p className="heading-medium gx-font-weight-medium">
                {this.lang.FrontBannerHeading3}&nbsp;
                <Link
                  to={this.getLink(this.constants.RoutePaths.SignUp)}
                  className="show-pointer"
                >
                  {this.lang.Register}
                </Link>
                &nbsp;
                {this.lang.FrontBannerHeading31}
              </p>
            </div>

            <p className="text-so-tiny gx-font-weight-medium">
              {this.lang.FrontBannerDisclaimer}
            </p>
          </div>
          <img src="/assets/images/winner.svg" alt="Coins" />
        </div>
        <Card className="placeholder-card" />
      </>
    );
  }
}
