import React from "react";

const LogoText = () => {
  return (
    <>
      <span style={{ color: "#f0645b" }}>Bit</span>
      <span style={{ color: "#028ddc" }}>Velocity</span>
    </>
  );
};

export default LogoText;
