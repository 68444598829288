import { BaseComponent } from "../../base/BaseComponent";
import React from "react";
import { Transitions } from "../../../../models/transitions";
import { mdFormControl } from "../../../../shared/form-control";
import ChartCard from "../../../../components/dashboard/Listing/ChartCard";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { Row } from "antd";
import "./wallet-component.less";
import { mdRegisterSocketEvent } from "../../../../models/register-socket-event";
import { SocketCustomEvents } from "../../../../enums/socket";

export default class WalletComponent extends BaseComponent {
  render() {
    this.initShorts();
    const { firstPair, secondPair, thirdPair, fourthPair } = this.state;
    return (
      <>
        <Row className="walletwrap gx-mb-5">
          {this.getWalletBox(fourthPair, "dodgerblue", "#BB1258")}
          {this.getWalletBox(thirdPair, "midnightblue", "#092453")}
          {this.getWalletBox(secondPair, "orange", "#C87000")}
          {this.getWalletBox(firstPair, "tomato", "#BB1258 ")}
        </Row>
      </>
    );
  }

  getTradesForPair = pair => {
    if (pair) {
      if (pair.id) {
        pair.trades = this.g.trades.filter(m => m.currency_pair_id == pair.id);
        if (pair.trades.length > this.tradesPerCard) {
          pair.trades.splice(this.tradesPerCard);
        }
      }
    }
    return pair;
  };

  getWalletBox = (pair: any, bgColor: string, barsFillColor: string) => {
    pair = this.getTradesForPair(pair);
    const anim = this.getAnimValuesForPair(pair, "last");
    if (anim) {
      this.state.animValues[anim.name] = anim;
    }
    const { data } = pair;
    return this.antd.colmd6(
      <ChartCard
        chartProperties={{
          title:
            data == null ? (
              ""
            ) : (
              <span>
                <img width="18" height="18" src={data.fc_icon} />
                &nbsp;
                {data.fc_name}/{data.tc_name}
                &nbsp;
                <span>24h</span>
              </span>
            ),
          prize: this.animatedCSSDiv(
            data == null ? (
              ""
            ) : (
              <>
                {(data.tc_symbol ? data.tc_symbol : "") +
                  "" +
                  (data.last
                    ? (data.last + data.last * 0.003).toFixed(data.tcd_scale)
                    : "")}
              </>
            ),
            anim
          ),
          icon: "stats",
          bgColor: bgColor,
          styleName: "up",
          desc: "",
          percent: ""
        }}
        children={
          <ResponsiveContainer width="100%" height={75}>
            <AreaChart
              data={pair.trades}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <Tooltip />
              <Area
                dataKey="amount"
                type="monotone"
                strokeWidth={0}
                stackId="2"
                stroke="#C87000"
                fill={barsFillColor}
                fillOpacity={1}
              />
            </AreaChart>
          </ResponsiveContainer>
        }
      />
    );
  };
  tradesPerCard: number;
  gotInitialTrades = false;
  constructor(props) {
    super(props);
    this.init();
  }

  old: any[];

  init() {
    this.tradesPerCard = 20;
    this.state = {
      firstPair: {
        name: "firstPair",
        trades: []
      },
      secondPair: {
        name: "secondPair",
        trades: []
      },
      thirdPair: {
        name: "thirdPair",
        trades: []
      },
      fourthPair: {
        name: "fourthPair",
        trades: []
      },
      animValues: {
        firstLast: new mdFormControl(
          this.getTransition(Transitions.origional),
          "firstLast"
        ),
        firstVolume: new mdFormControl(
          this.getTransition(Transitions.origional),
          "firstVolume"
        ),
        secondLast: new mdFormControl(
          this.getTransition(Transitions.origional),
          "secondLast"
        ),
        secondVolume: new mdFormControl(
          this.getTransition(Transitions.origional),
          "secondVolume"
        ),
        thirdLast: new mdFormControl(
          this.getTransition(Transitions.origional),
          "thirdLast"
        ),
        thirdVolume: new mdFormControl(
          this.getTransition(Transitions.origional),
          "thirdVolume"
        ),
        fourthLast: new mdFormControl(
          this.getTransition(Transitions.origional),
          "fourthLast"
        ),
        fourthVolume: new mdFormControl(
          this.getTransition(Transitions.origional),
          "fourthVolume"
        )
      }
    };
    this.loadPairs();
  }

  loadPairs = () => {
    let { firstPair, secondPair, thirdPair, fourthPair } = this.state;
    let sortedPairs: any[] = [];
    let newPairs = [];
    let histr = this.g.briefHistory;
    if (histr) {
      histr = histr.map(m => {
        if (m.data) {
          return m.data;
        }
        return m;
      });
      sortedPairs = histr.sort((a, b) => {
        if (a.volume > b.volume) {
          return -1;
        } else {
          return 1;
        }
      });
      if (!firstPair.id) {
        firstPair.data = sortedPairs.length > 0 ? sortedPairs[0] : {};
        firstPair.id = firstPair.data.id;
        newPairs.push(firstPair);
      } else {
        firstPair.data = sortedPairs.filter(m => m.id == firstPair.id)[0];
      }
      if (!secondPair.id) {
        secondPair.data = sortedPairs.length > 1 ? sortedPairs[1] : {};
        secondPair.id = secondPair.data.id;
        newPairs.push(secondPair);
      } else {
        secondPair.data = sortedPairs.filter(m => m.id == secondPair.id)[0];
      }
      if (!thirdPair.id) {
        thirdPair.data = sortedPairs.length > 2 ? sortedPairs[2] : {};
        thirdPair.id = thirdPair.data.id;
        newPairs.push(thirdPair);
      } else {
        thirdPair.data = sortedPairs.filter(m => m.id == thirdPair.id)[0];
      }
      if (!fourthPair.id) {
        fourthPair.data = sortedPairs.length > 3 ? sortedPairs[3] : {};
        fourthPair.id = fourthPair.data.id;
        newPairs.push(fourthPair);
      } else {
        fourthPair.data = sortedPairs.filter(m => m.id == fourthPair.id)[0];
      }
      let newState = {
        firstPair: firstPair,
        secondPair: secondPair,
        thirdPair: thirdPair,
        fourthPair: fourthPair
      };
      this.updateState(newState, _ => {
        this.getInitialTrades();
      });
    }
  };

  getInitialTrades = () => {
    if (!this.gotInitialTrades) {
      let { firstPair, secondPair, thirdPair, fourthPair } = this.state;
      if (firstPair.id && secondPair.id && thirdPair.id && fourthPair.id) {
        this.gotInitialTrades = true;
        this.socket.emitOneTimeEvent(
          new mdRegisterSocketEvent(SocketCustomEvents.GetInitialRecentTrades, {
            currency_pair_id: firstPair.id,
            length: this.tradesPerCard
          })
        );
        this.socket.emitOneTimeEvent(
          new mdRegisterSocketEvent(SocketCustomEvents.GetInitialRecentTrades, {
            currency_pair_id: secondPair.id,
            length: this.tradesPerCard
          })
        );
        this.socket.emitOneTimeEvent(
          new mdRegisterSocketEvent(SocketCustomEvents.GetInitialRecentTrades, {
            currency_pair_id: thirdPair.id,
            length: this.tradesPerCard
          })
        );
        this.socket.emitOneTimeEvent(
          new mdRegisterSocketEvent(SocketCustomEvents.GetInitialRecentTrades, {
            currency_pair_id: fourthPair.id,
            length: this.tradesPerCard
          })
        );
      }
    }
  };

  afterReceivingProps = () => {
    this.loadPairs();
  };

  componentDidUpdate = () => {
    if (this.g.briefHistory) {
      if (this.g.briefHistory != this.old) {
        this.old = this.g.briefHistory;
      }
    }
  };

  getAnimValuesForPair = (pair, propName: string) => {
    if (!pair) {
      return null;
    }
    let controlName: string = pair.name + propName;
    let anim: any = new mdFormControl(
      this.getTransition(Transitions.origional),
      controlName
    );
    let propValue = null;
    if (pair.data) {
      propValue = pair.data[propName];
    }
    if (this.old) {
      let old = this.old.filter(m => m.id == pair.id)[0];
      const index = this.old.indexOf(old);
      if (old[propName] > propValue) {
        anim = new mdFormControl(
          this.runTransition(Transitions.lesser),
          controlName
        );
      } else if (old[propName] < propValue) {
        anim = new mdFormControl(
          this.runTransition(Transitions.greater),
          controlName
        );
      } else {
        anim = new mdFormControl(
          this.runTransition(Transitions.origional),
          controlName
        );
      }
    }
    return anim;
  };
}
