import React, { Suspense } from "react";
import { BaseComponent } from "../../base/BaseComponent";
import Widget from "../../../../components/Widget/index";
import "./map-component.less";
import { Row } from "antd";

export default class MapComponent extends BaseComponent {
  render() {
    this.initShorts();
    return (
      <div
        // styleName="gx-order-history"
        // extra={<p className="gx-text-primary gx-mb-0 gx-pointer">{""}</p>}
        className="gx-mb-6"
      >
        {/* <h1>{this.lang.Market}</h1> */}
        <div className="worldwrap">
          <div className="container gx-text-center">
            <h1>{this.lang.MapComponentHeading}</h1>
            <div className="world-txt text-big">
              {this.lang.MapComponentPara}
            </div>
          </div>
          <div className="container-fluid">
            <Suspense fallback={<div>Loading...</div>}>
              <img
                src="/assets/images/world-map.webp"
                className="img-responsive"
                alt="World Map"
              />
            </Suspense>
          </div>
        </div>
        <Row className="servicewrap">
          {this.antd.colmd8(
            <>
              <h2>{this.lang.PartBuying}</h2>
              <p>{this.lang.PartBuyingPara}</p>
            </>
          )}
          {this.antd.colmd8(
            <>
              <h2>{this.lang.Support}</h2>
              <p>{this.lang.SupportPara}</p>
            </>
          )}
          {this.antd.colmd8(
            <>
              <h2>{this.lang.API}</h2>
              <p>{this.lang.APIPara}</p>
            </>
          )}
        </Row>
      </div>
    );
  }

  constructor(props) {
    super(props);
  }
}
